import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {firestore} from "../config/firebase";
import Spinner from "react-bootstrap/Spinner";
import Switch from "react-switch";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {injectStyle} from "react-toastify/dist/inject-style";
import atl_zipCode from "../constants/zipCode";

import paymentImage from "../images/paymentImage.png";
import creditIcon from "../images/creditIcon.png";
import appleIcon from "../images/appleIcon.png";

let temp = false;
let newTemp = false;

if (typeof window !== "undefined") {
  injectStyle();
}

let today = new Date();
let day = today.getDay();
let today_Hour = today.getHours();
let today_minute = today.getMinutes();
let now_Mins = today_Hour * 60 + today_minute;

const WebViewPaymentMethodForm = (props) => {
  let history = useHistory();
  const submitHandler = (e) => {
    e.preventDefault();
    history.push("/orderstatus");
  };
  const [cvc, setCvc] = useState("");
  const [card, setCard] = useState("");
  const [exp, setExp] = useState("");
  const [zip, setZip] = useState("");
  const [cardZip, setCardZip] = useState("");
  const [city, setCity] = useState("");
  const [disStreet, setDisStreet] = useState("");
  const [disLineAddress, setDisLineAddress] = useState("");
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [placeDate, setPlaceDate] = useState("");
  const [placeStatus, setPlaceStatus] = useState(true);
  const [confirmDate, setConfirmDate] = useState("");
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [dropDate, setDropDate] = useState("");
  const [dropStatus, setDropStatus] = useState(false);
  const [taxFee, setTaxFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [promo, setPromo] = useState("");
  const [specialIns, setSpecialIns] = useState("");
  const [opentStatus, setOpentStatus] = useState(true);
  const [promoData, setPromoData] = useState([]);
  const [loyalCodeFlag, setLoyalCodeFlag] = useState(false);
  const [loyalCodeNum, setLoyalCodeNum] = useState(0);
  const [loyalCode, setLoyalCode] = useState(0);
  const [sentInviteUserId, setSentInviteUserId] = useState("");
  const [sentInviteUserReferralValue, setSentInviteUserReferralValue] =
    useState(0);
  const [currentReferralValue, setCurrentReferralValue] = useState(0);
  const [referralValue, setReferralValue] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(5);
  const [discount, setDiscount] = useState(0);
  const [checkingOut, isCheckingOut] = useState(false);
  const toastId = React.useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState("");
  const [ageChecked, setAgeChecked] = useState(false);
  const [idChecked, setIdChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Credit");
  const [online, setOnline] = useState(true);
  const [isSave, setIsSave] = useState(true);

  let dispensaryEmail = "";
  let disOwnerName = "";
  let storeZipCode = "";

  const iconStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: 15,
    color: "#878787",
    paddingRight: 2,
  };

  const userID = props.userID;
  localStorage.setItem("userUid", userID);

  useEffect(() => {
    const getInitialdata = async () => {
      firestore
        .collection("User")
        .doc(userID)
        .onSnapshot((documentSnapshot) => {
          const fetchUserData = documentSnapshot.data();
          const row = {
            firstName: fetchUserData.fristName,
            lastName: fetchUserData.lastName,
            email: fetchUserData.email,
            dropStreet: fetchUserData.dropStreet,
            dropCity: fetchUserData.dropCity,
            dropZip: fetchUserData.dropZip,
            dropLineAddress: fetchUserData.dropLineAddress,
            phoneNum: fetchUserData.phoneNum,
            promoFlag: fetchUserData.promoFlag,
            referralValue: fetchUserData.referralValue,
            newFlag: fetchUserData.newFlag,
            callFlag: fetchUserData.callFlag,
            loyalCodeNum: fetchUserData.loyalCodeNum,
            loyalCode: fetchUserData.loyalCode,
            cardFirstName: fetchUserData?.cardFirstName,
            cardLastName: fetchUserData?.cardLastName,
            cardExp: fetchUserData?.cardExp,
            cardNumber: fetchUserData?.cardNumber,
            cardZip: fetchUserData?.cardZip,
            cardCvc: fetchUserData?.cardCvc,
            saveCard: fetchUserData?.saveCard,
          };
          if (row.saveCard) {
            setFirstName(row.cardFirstName);
            setLastName(row.cardLastName);
            setCard(row.cardNumber);
            setExp(row.cardExp);
            setCvc(row.cardCvc);
            setCardZip(row.cardZip);
          }
          setZip(row.dropZip);
          setDisStreet(row.dropStreet);
          setCity(row.dropCity);
          setUserData(row);
          setPhoneNumber(row.phoneNum);
          row.dropLineAddress && setDisLineAddress(row.dropLineAddress);
        });

      const referralDocRef = firestore.collection("ReferralValue").doc(userID);
      const referralDocSnapshot = await referralDocRef.get();
      if (referralDocSnapshot.exists) {
        let referralVal = referralDocSnapshot.data().referralValue;
        setReferralValue(referralVal);
        setCurrentReferralValue(referralVal);
      }

      let zipCode = localStorage.getItem("zip");
      if (atl_zipCode.zip700.indexOf(zipCode) >= 0) {
        setTaxFee(0.07);
      } else if (atl_zipCode.zip775.indexOf(zipCode) >= 0) {
        setTaxFee(0.0775);
      } else if (atl_zipCode.zip800.indexOf(zipCode) >= 0) {
        setTaxFee(0.08);
      } else {
        setTaxFee(0.089);
      }
      const cartRef = firestore.collection("Carts");
      const query = cartRef.where("userId", "==", userID);
      query.onSnapshot((documentSnapshot) => {
        let cartItemdata = [];
        if (!documentSnapshot.empty) {
          documentSnapshot.forEach((element) => {
            const fetchUserData = element.data();
            const row = {
              Description: fetchUserData.Description.slice(0, 254),
              Tag: fetchUserData.Tag,
              id: fetchUserData.id.slice(0, 30),
              itemImage: fetchUserData.itemImage,
              itemNum1: fetchUserData.itemNum1,
              itemId: fetchUserData.itemId,
              priceValue: fetchUserData.priceValue,
              productName: fetchUserData.productName.slice(0, 30),
              num: fetchUserData.num,
              variantRealArray: fetchUserData?.variantRealArray,
              variantIndex: fetchUserData?.variantIndex,
              storeId: fetchUserData.storeId,
              storeName: fetchUserData.storeName,
            };
            cartItemdata.push(row);
          });
          setItems(cartItemdata);
          setStoreId(cartItemdata[0].storeId);
          setStoreName(cartItemdata[0].storeName);
          timeHandler(cartItemdata[0].storeId);
          let totalAmount = 0;
          cartItemdata.map((item) => {
            totalAmount = totalAmount + item.priceValue * item.num;
            setTotal(totalAmount);
          });
        } else {
          setItems([]);
        }
      });

      const promoDocRef = firestore.collection("PromoCodes");
      const promoDocSnapshot = await promoDocRef.get();
      const promoCodeData = promoDocSnapshot.docs.map((doc) => doc.data());
      setPromoData(promoCodeData);
    };

    getInitialdata();
  }, []);

  const timeHandler = (storeId) => {
    firestore
      .collection("User")
      .doc(storeId)
      .onSnapshot((documentSnapshot) => {
        const fetchStoreData = documentSnapshot.data();
        let openStatus = fetchStoreData.storeHours[day].openStatus;
        let online = fetchStoreData.storeHours[day].online;

        if (online == true) {
          if (openStatus) {
            localStorage.setItem("openStatus", "true");
            setOpentStatus(true);
          } else {
            localStorage.setItem("openStatus", "false");
            setOpentStatus(false);
          }
        }
      });
  };

  const expHandler = (e) => {
    let value = e.nativeEvent.data;
    if (value >= 0 && value <= 9) {
      if (e.target.value.length === 2 && !e.target.value.includes("/")) {
        e.target.value += "/";
      } else if (e.target.value.length === 1 && e.target.value.includes("/")) {
        e.target.value = "";
      }
      setExp(e.target.value);
    }
  };
  const cvcHandler = (e) => {
    const { value, maxLength } = e.target;
    const message = value.slice(0, maxLength);
    setCvc(message);
  };

  const cardInput = (e) => {
    let value = e.nativeEvent.data;
    if ((value >= 0 && value <= 9) || value === " ") {
      setCard(
        e.target.value
          .replace(/\s/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim()
      );
    }
  };



  const orderReview = async () => {
    setLoading(true);
    if (!card || !cvc || !exp || !firstName || !lastName) {
      showToast("Please fill all fields.");
      setLoading(false);
      isCheckingOut(false);
    } else {
      const userDocRef = firestore.collection("User").doc(userID);
      await userDocRef.update({
        cardFirstName: firstName,
        cardLastName: lastName,
        cardExp: exp,
        cardNumber: card,
        cardZip: cardZip,
        cardCvc: cvc,
        saveCard: isSave,
        selectedPaymentMethod:paymentMethod
      });
      setLoading(false);
      window.ReactNativeWebView.postMessage("success");
    }
  };

  // Toast function: makes sure multiple toasts don't show at the same time
  const showToast = (val) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast(val);
    }
  };

  const storeOpenStatusChange = async () => {
    await setIsSave(!isSave);
  };

  return (
    <div className="checkout-form-cont">
      <div className="checkout-form">
        <img
          src={paymentImage}
          style={{ width: 290, height: 62, marginTop: 20, marginBottom: 20 }}
          alt="paymentImage"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          <div
              className={paymentMethod === 'Credit' ? 'payItem selected' : 'payItem'}
              onClick={() => setPaymentMethod("Credit")}
          >
            <img src={creditIcon} style={{ width: 31, height: 21 }} />
            <p
              style={{
                fontFamily: "Poppins-Medium !important",
                fontSize: 10,
                marginTop: 15,
              }}
            >
              Credit Card
            </p>
          </div>
          <div
              className={paymentMethod === 'ApplePay' ? 'payItem selected' : 'payItem'}
              onClick={() => setPaymentMethod("ApplePay")}
          >
            <img src={appleIcon} style={{width: 22, height: 27}}/>
            <p
                style={{
                  fontFamily: "Poppins-Medium !important", fontSize: 10, marginTop: 13, color: "#868889",
                }}
            >
              Apple pay
            </p>
          </div>
        </div>
        {paymentMethod === 'Credit' && <div className="CreditCardContainer">
          <div className="webView-checkout-form-cardd">
          <div className="webView-checkout-form-card">
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              type="text"
            />
          </div>
          <div className="webView-checkout-form-card">
            <input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
            />
          </div>
        </div>
        <div className="webView-checkout-form-pr">
          <div className="webView-checkout-form-promo">
            <input
              onChange={(e) => cardInput(e)}
              maxLength={19}
              value={card}
              placeholder="Card number"
            />
          </div>
        </div>
        <div className="webView-checkout-form-address">
          <div className="webView-checkout-form-address-item">
            <input
              value={exp}
              onChange={(e) => expHandler(e)}
              placeholder="Month/Year"
              maxLength={5}
            />
          </div>
          <div className="webView-checkout-form-address-item">
            <input
              placeholder="CVV"
              value={cvc}
              onChange={cvcHandler}
              type="number"
              maxLength="4"
            />
          </div>
          <div className="webView-checkout-form-address-item">
            <input
              value={cardZip}
              onChange={(e) => setCardZip(e.target.value)}
              placeholder="Zip code"
              maxLength={5}
              type="number"
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginLeft: 20,
            width: "90%",
          }}
        >
          <Switch
            onChange={() => {
              storeOpenStatusChange();
            }}
            checked={isSave}
            width={35}
            height={18}
            uncheckedIcon={<div style={iconStyle}></div>}
            checkedIcon={<div style={iconStyle}></div>}
            offColor="#878787"
            onColor="#63D274"
            offHandleColor="#fff"
            // offHandleColor="#fff"
            onHandleColor="#fff"
            handleDiameter={14}

          />
          <p
            style={{
              fontSize: 12,
              fontFamily: "Poppins-Medium !important",
              marginLeft: 20,
              color: "black !important",
            }}
          >
            Save this card
          </p>
        </div>
        <p
          style={{
            fontSize: 16,
            width: "90%",
            marginTop: 10,
            fontFamily: "Avenir",
            fontWeight: 500,
            textAlign: 'center'
          }}
        >
          Note:{" "}
          <i>
            We accept all major credit/debit cards.
          </i>
        </p>
        </div>}

        {paymentMethod === 'ApplePay' && <div className="ApplePayContainer">
          You'll get to make your payment using Apple Pay on the next screen.
        </div>}

        <button
          className="checkout-form-submit"
          style={{
            width: "90%",
            borderRadius: 5,
            backgroundColor: "#1C280B",
            boxShadow: "none",
          }}
          type="submit"
          onClick={orderReview}
        >
          Review Order
        </button>
      </div>

      <ToastContainer />
      {loading && (
        <Spinner
          animation="border"
          variant="primary"
          className="cart-loading-border"
        />
      )}
    </div>
  );
};
export default WebViewPaymentMethodForm;
